import React, { Fragment, useState } from "react";
// import "../styles/styles.css"
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  ModalHeader,
  Label,
  Input,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from "../../data/layouts";
import Select from "react-select";
import { useLocation } from "react-router";
import Loading from "./Loading";
import { FiMessageSquare, FiMail, FiBell } from "react-icons/fi";
import { FaEnvelope, FaMailBulk, FaBell } from "react-icons/fa";
import TextField from "@material-ui/core/TextField";
import { TiTimes } from "react-icons/ti";
import moment from "moment/moment";

import { CiExport } from "react-icons/ci";

const options = [
  { value: "User", label: "Users" },
  { value: "Admin", label: "Adminstrator" },
];

const AdminList = (props) => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [hideDirector, setHideDirector] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [VaryingContent, setVaryingContent] = useState(false);

  const [hide, setHide] = useState(false);
  const [status, setStatus] = useState("Admin");

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  async function getUsers() {
    setLoader(true);
    const params = new URLSearchParams({ type: "Admin" });
    const res = await adminServices.getUsers(params);
    if (res.status) {
      setLoader(false);
      // setHideDirector("Admin")
      setData(res.data.users);
    } else {
      setData([]);
    }
  }
  useEffect(() => {
    getUsers();
  }, [status]);

  const selectDropdown = (event) => {
    setStatus(event.value);
  };
  useEffect(() => {
    if (status === "Admin") {
      setHideDirector(false);
    } else {
      setHideDirector(true);
    }
  }, [status]);

  const tableData = {
    name: {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    email: {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      center: true,
    },
    mobile: {
      name: "Mobile",
      selector: (row) => row?.mobile,
      sortable: true,
      center: true,
    },
    type: {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
      center: true,
    },

    orgname: {
      name: "Organization name",
      selector: (row) => row?.organization_name,
      sortable: true,
      center: true,
    },
    role: {
      name: "Role",
      selector: (row) => row?.role,
      sortable: true,
      center: true,
    },

    action: {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <span>
              {hideDirector && (
                <i
                  style={{
                    cursor: "pointer",
                    width: 35,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(40, 167, 69)",
                  }}
                  onClick={() => onViewNavigate(row)}
                  className="fa fa-eye"
                ></i>
              )}
            </span>

            <span>
              <i
                className="fa fa-pencil"
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
                onClick={() => onUpdateUser(row)}
              ></i>
            </span>
            <span>
              <i
                className="fa fa-trash"
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
                onClick={() => onRemoveUser(row.mobile)}
              ></i>
            </span>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  };

  var conf = localStorage.getItem("adminConf");
  console.log("CONF", conf);

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: "name", title: "Name", selected: true },
      { value: "email", title: "Email", selected: true },
      { value: "mobile", title: "Mobile", selected: true },
      { value: "type", title: "Type", selected: true },
      { value: "orgname", title: "Organization name", selected: false },
      { value: "role", title: "Role", selected: false },
      { value: "action", title: "Action", selected: true },
    ];
  }

  const [filter, setFilter] = useState(conf);

  const content =
    filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {
    if (content.includes(d)) {
      tableColumns.push(tableData[d]);
    }
  });

  const openPopHandler = () => {
    setVaryingContent(!VaryingContent);
    setFilter(filter);
  };

  const showhideHandler = (values, checked) => {
    const index = filter.findIndex((val) => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  };

  const onViewNavigate = (row) => {
    history(`${process.env.PUBLIC_URL}/app/listingdata/${row._id}`, {
      state: row,
    });
  };

  const handler = async (id) => {
    const response = await adminServices.exportUsers({});

    const href = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `users_${moment().format("YYYY-MM-DD-HH-mm")}`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const onRemoveUser = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        adminServices.deleteUser(id);
        // console.log("res",res)

        SweetAlert.fire("Deleted!", "Your User has been deleted.", "success");
        getUsers();
      } else {
        SweetAlert.fire("Your file is safe!");
      }
      getUsers();
    });
    getUsers();
  };
  const onUpdateUser = (row) => {
    // history(`${process.env.PUBLIC_URL}/app/user/${row._id}`, { state: row });
    history(`${process.env.PUBLIC_URL}/app/user/${row._id}`, { state: row });
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Table"
        title="Administrators"
        // buttonOptions={() =>
        // <Button color="primary"
        //   onClick={() => handler()}
        // >Export</Button>
        // <FcExport onClick={() => handler()}style={{ color: "#d63384", float: "right" }} size={24} />
        // }

        renderOptions={() => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <CiExport
                onClick={() => handler()}
                style={{
                  color: "#d63384",
                  marginRight: "6%",
                  fontWeight: "bold",
                }}
                size={28}
              />

              <div>
                {status === "Admin" && (
                  <Button
                    color="primary"
                    onClick={() =>
                      history(`${process.env.PUBLIC_URL}/app/user/${layout}`)
                    }
                  >
                    {"Add"}
                  </Button>
                )}
              </div>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => openPopHandler()}
              >
                Filter
              </Button>
            </div>
          );
        }}
      />
      <div style={{ alignSelf: "flex-start" }}></div>
      {loader ? (
        <Loading />
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <Modal
                  isOpen={VaryingContent}
                  style={{ width: "30%" }}
                  onClosed={() =>
                    localStorage.setItem("adminConf", JSON.stringify(filter))
                  }
                  toggle={() => setVaryingContent(!VaryingContent)}
                >
                  <ModalHeader
                    toggle={() => setVaryingContent(!VaryingContent)}
                  >
                    {"Display"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      {filter.map((i) => {
                        return (
                          <div style={{ margin: "20px" }}>
                            <input
                              type="checkbox"
                              defaultChecked={i.selected}
                              value={i.selected}
                              name={i}
                              onChange={(e) =>
                                showhideHandler(i, e.target.checked)
                              }
                            />
                            <span style={{ marginLeft: "30px" }}>
                              {i.title}
                            </span>
                          </div>
                        );
                      })}
                    </Form>
                  </ModalBody>
                </Modal>

                <CardBody>
                  <DataTable
                    data={data}
                    columns={tableColumns}
                    center={true}
                    selectableRows
                    // persistTableHead
                    // contextActions={contextActions}
                    // onSelectedRowsChange={handleRowSelected}
                    // clearSelectedRows={toggleCleared}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
export default AdminList;
