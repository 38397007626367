import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Table,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from "../../data/layouts";
import moment from "moment/moment";
import Select from "react-select";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Loading from "./Loading";
import commonServices from "../../api/commonService";
import { useSearchParams } from "react-router-dom";

const Enquiries = () => {
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState(data);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("Pending");
  const [loader, setLoader] = useState(false);
  const [VaryingContentone, setVaryingContentone] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();


  const [currentPage, setPage] = useState(searchParams?.get("page") | 1);
  const [pageItems, setPageItems] = useState(searchParams?.get("items") || 20);
  const [totalItems, setTotalItems] = useState(0);
  const [defaultPage, setDefaultPage] = useState(searchParams?.get("page") | 1);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const options = [
    { value: "all", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },

  ];

  const [feedbacks, setFeedbacks] = useState([])

  useEffect(() => {
    async function getEnquiries() {
      let qryString = "";
      let obj = {};
      if (status !== "all") {
        obj.status = status;
      }
      obj.items = pageItems;
      obj.page = currentPage - 1;
      qryString = new URLSearchParams(obj).toString();


      setLoader(true);
      const res = await adminServices.getEnquiries(qryString);
      if (res.status) {
        setLoader(false);
        setData(res.data.enquiries);
        setTotalItems(res.data.total)
      } else {
        setData("");
      }
    }
    getEnquiries();


    async function getFeedbacks() {
      const res = await commonServices.getFeedbacks("enquiry_cancel");
      setFeedbacks(res.data);

    }
    getFeedbacks();
  }, [status, currentPage, pageItems]);

  const selectDropdown = (event) => {
    setStatus(event.value);
  };

  const onEnquires = (d) => {
    history(`${process.env.PUBLIC_URL}/app/enquiriesdata/${d._id}`, {
      state: d,
    });
  };

  const tableData = {
    id: {
      name: "Id",
      selector: (row) => row?.uid,
      sortable: true,
    },
    reservation: {
      name: "Reservation",
      selector: (row) => row?.booking_id?.reservation_id?.uid,
      sortable: true,
    },
    status: {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      center: true,
    },
    createdate: {
      name: "Date",
      selector: (row) => moment(row?.createdAt).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
    },
    quotedAmount: {
      name: "Quote Amt",
      selector: (row) => row?.quoted_amount,
      sortable: true,
      center: true,
    },
    remarks: {
      name: "Remarks",
      selector: (row) => {
        const r = feedbacks.filter((item) => item._id == row.remarks);
        if (r.length > 0) {
          return <p style={{ overflow: "auto" }}>{r[0].title}</p>
        }
        return <p style={{ overflow: "auto" }}>{r}</p>

      },
      sortable: true,
      center: true,


    },
    rentalperiodfrom: {
      name: "From",
      selector: (row) =>
        moment(row.booking_id?.rental_period[0]).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
    },

    to: {
      name: "to",
      selector: (row) =>
        moment(row.booking_id?.rental_period[1]).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
    },
    qty: {
      name: "Qty",
      selector: (row) => row?.booking_id?.quantity,
      sortable: true,
      center: true,
    },
    shift: {
      name: "Shift",
      selector: (row) => row?.booking_id?.shift,
      sortable: true,
      center: true,
    },
    email: {
      name: "S Email",
      selector: (row) => row?.supplier_id?.email,
      sortable: true,
      center: true,
    },
    mobile: {
      name: "S Mobile",
      selector: (row) => row?.supplier_id?.mobile,
      sortable: true,
      center: true,
    },
    

    name: {
      name: "S Name",
      selector: (row) => <p onClick={() => history(`${process.env.PUBLIC_URL}/app/listingdata/${row.supplier_id._id}`, {
        state: row.supplier_id
      }) }>{row?.supplier_id?.name} </p>,
      sortable: true,
      center: true,
    },
    orgname: {
      name: "S Org Name",
      selector: (row) => row?.supplier_id?.organization_name,
      sortable: true,
      center: true,
    },
  };

  var conf = localStorage.getItem("enquiresConf");

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: "id", title: "Id", selected: true },
      { value: "reservation", title: "Reservation", selected: true },
      { value: "status", title: "Status", selected: true },
      { value: "createdate", title: "Date", selected: true },
      {
        value: "rentalperiodfrom",
        title: "From",
        selected: false,
      },
      {
        value: "quotedAmount",
        title: "Quote",
        selected: true,
      },
      { value: "to", title: "To", selected: false },
      { value: "qty", title: "Quantity", selected: false },
      { value: "shift", title: "Shift", selected: false },
      {
        value: "remarks", title: "Remarks", selected: false
      },
      { value: "email", title: "Email", selected: false },
      { value: "mobile", title: "Mobile", selected: false },
      { value: "name", title: "Name", selected: false },
      { value: "orgname", title: "Organization", selected: true },
    ];
  }

  const [filter, setFilter] = useState(conf);

  const content =
    filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {
    if (content.includes(d)) {
      tableColumns.push(tableData[d]);
    }
  });


  const handlePageChange = (page) => {
    setPage(page);
    setSearchParams({page: page, items : pageItems})
    setDefaultPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(page);
    setPageItems(newPerPage);
  };

  const openPopHandler = () => {
    setVaryingContentone(!VaryingContentone);
    setFilter(filter);
  };

  //   const handleCheckboxChange = (value, checked) => {
  //     if (checked) {
  //         setCheckboxes([...checkboxes, value]);
  //     } else {
  //         setCheckboxes(checkboxes.filter((item) => item !== value));
  //     }
  // };
  const showhideHandler = (values, checked) => {
    const index = filter.findIndex((val) => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  };

  const exportEnquiries = async () => {
    const response = await adminServices.exportEnquiries(status);
    const href = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `enq_${moment().format("YYYY-MM-DD-HH-mm")}`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  return (
    <Fragment>
      <Breadcrumb
        parent="Table"
        title="Enquires"
        renderOptions={() => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <div>
                  <Form.Control
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search id.."
                  />
                </div>
              </div>

              <div style={{ marginLeft: "8%", width: "100%" }}>
                <Select
                  onChange={(event) => {
                    selectDropdown(event);
                  }}
                  // styles={{ width: "40%"}}
                  options={options}
                />
              </div>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => openPopHandler()}
              >
                Filter
              </Button>
            </div>
          );
        }}
      />


      <Container fluid={true}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => exportEnquiries()}>Export</Button>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Modal
                isOpen={VaryingContentone}
                style={{ width: "30%" }}
                onClosed={() =>
                  localStorage.setItem("enquiresConf", JSON.stringify(filter))
                }
                toggle={() => setVaryingContentone(!VaryingContentone)}
              >
                <ModalHeader
                  toggle={() => setVaryingContentone(!VaryingContentone)}
                >
                  {"Display"}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    {filter.map((i) => {
                      return (
                        <div key={"eqn" + i} style={{ margin: "20px" }}>
                          <input
                            type="checkbox"
                            defaultChecked={i.selected}
                            value={i.selected}
                            name={i}
                            onChange={(e) =>
                              showhideHandler(i, e.target.checked)
                            }
                          />
                          <span style={{ marginLeft: "30px" }}>
                            {i.title}
                          </span>
                        </div>
                      );
                    })}
                  </Form>
                </ModalBody>
              </Modal>

              <CardBody>


                <DataTable
                  data={data}
                  columns={tableColumns}
                  center={true}
                  pagination
                  paginationServer
                  progressPending={loader}
                  paginationPerPage={pageItems}
                  paginationTotalRows={totalItems}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[20, 50, 100, 1000]}
                  paginationDefaultPage={defaultPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Enquiries;
