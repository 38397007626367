import React, { Fragment, useState } from "react";
// import "../styles/styles.css"
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  ModalHeader,
} from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from "../../data/layouts";
import { useLocation } from "react-router";
import Loading from "./Loading";
import { FaBell } from "react-icons/fa";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { CiExport } from "react-icons/ci";
import { toast } from "react-toastify";

const options = [
  { value: "User", label: "Users" },
  { value: "Admin", label: "Adminstrator" },
];

const UserList = (props) => {
  const location = useLocation();
  const initialLoad = location?.state;
  const [data, setData] = useState("");
  const [hideDirector, setHideDirector] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const [VaryingContentone, setVaryingContentone] = useState(false);

  const [page, setPage] = useState(0);
  const [pageItems, setPageItems] = useState(30);
  const [totalItems, setTotalItems] = useState(0);

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [status, setStatus] = useState(
    initialLoad === "Admin" ? "Admin" : "User"
  );
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const Popup = ({ onClose }) => {
    const [selectedIcon, setSelectedIcon] = useState(null);
  };

  const closeEmail = () => {
    setSendEmail(!sendEmail);
  };

  const closeMessage = () => {
    setSendMessage(!sendMessage);
  };

  const closeNotification = () => {
    setSendNotification(!sendNotification);
  };

  const togglePopup = () => {
    setSendNotification(!sendNotification);
  };

  async function getUsers() {
    setLoader(true);
    const params = new URLSearchParams({
      type: "User",
      primary_account: "renter",
      page: page,
      items: pageItems,
    });

    const res = await adminServices.getUsers(params);
    if (res.status) {
      setLoader(false);
      setData(res.data.users);
      setTotalItems(res.data.total);
    } else {
      setData([]);
    }
  }

  useEffect(() => {
    getUsers();
  }, [status]);

  const handleSubmit = async (fcm, id) => {
    const res = await adminServices.sendNotification(fcm, id, {
      notification: {
        title: title,
        body: body,
      },
      android: {
        priority: "high",
      },
      data: {
        title: title,
        body: body,
        // navigate_to: "Listings",
        // user_type: "supplier",
        // screen: "Approved",
      },
    });
    if (res.status) {
      toast(res.message);
    } else {
      toast.error(res.message);
    }
  };


  const selectDropdown = (event) => {
    setStatus(event.value);
  };
  useEffect(() => {
    if (status === "Admin") {
      setHideDirector(false);
    } else {
      setHideDirector(true);
    }
  }, [status]);

  const tableData = {
    id: {
      name: "ID",
      selector: (row) => row._id,
      sortable: true,
    },
    name: {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    email: {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      center: true,
    },
    mobile: {
      name: "Mobile",
      selector: (row) => row?.mobile,
      sortable: true,
      center: true,
    },
    type: {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
      center: true,
    },
    orgname: {
      name: "Organization name",
      selector: (row) => row?.organization_name,
      sortable: true,
      center: true,
    },
    noofbookings: hideDirector
      ? {
          name: "No. of Bookings",
          selector: (row) => row?.bookings,
          sortable: true,
          center: true,
        }
      : "",

    date: hideDirector
      ? {
          name: "Date",
          selector: (row) => {
            return moment(row?.createdAt).format("DD-MM-YYYY");
          },
          sortable: true,
          center: true,
        }
      : "",

    sendnotifications: hideDirector
      ? {
          name: "send notification",
          selector: (row) => {
            return (
              <div>
                <i
                  class="fa fa-share-square"
                  onClick={togglePopup}
                  style={{ fontSize: "20px", color: "#0d6efd" }}
                  aria-hidden="true"
                ></i>
                {isOpen && <Popup onClose={togglePopup} />}
                {sendEmail && (
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      // width: "600px",
                      backgroundColor: "white",
                      padding: "40px",
                      borderRadius: "8px",
                      height: "40%",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      zIndex: 999,
                    }}
                  >
                    <h5 style={{ justifyContent: "center" }}>Send Email</h5>

                    <div style={{ padding: "10px 0px", marginTop: "0px" }}>
                      To :{" "}
                    </div>
                    <div style={{ padding: "5px 0px", marginTop: "0px" }}>
                      {" "}
                      <TextField id="standard-basic" label="Email" />{" "}
                    </div>
                    <div style={{ padding: "10px 0px", marginTop: "20px" }}>
                      Description :{" "}
                    </div>

                    <textarea
                      rows="4"
                      cols="60"
                      style={{ marginTop: "0%" }}
                    ></textarea>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "25px",
                      }}
                    >
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={closeEmail}
                      >
                        Cancel
                      </Button>
                      <Button onClick={togglePopup}>Send</Button>
                    </div>
                  </div>
                )}

                {sendMessage && (
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      // width: "600px",
                      backgroundColor: "white",
                      padding: "40px",
                      borderRadius: "8px",
                      height: "30%",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      zIndex: 999,
                    }}
                  >
                    <h5 style={{ justifyContent: "center" }}>Send Message</h5>

                    <textarea
                      rows="4"
                      cols="60"
                      style={{ marginTop: "10%" }}
                    ></textarea>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "25px",
                      }}
                    >
                      <Button
                        style={{ marginRight: "5px" }}
                        onClick={closeMessage}
                      >
                        Cancel
                      </Button>
                      <Button onClick={togglePopup}>Send</Button>
                    </div>
                  </div>
                )}

                {sendNotification && (
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      // width: "600px",
                      backgroundColor: "white",
                      padding: "40px",
                      borderRadius: "8px",
                      // height: "30%",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      zIndex: 999,
                    }}
                  >
                    <h5 style={{ justifyContent: "center" }}>
                      Send Notifications
                    </h5>
                    <div>
                      <div>
                        <h2
                          style={{
                            color: "blue",
                            fontSize: 18,
                            marginTop: "2%",
                          }}
                        >
                          Title :
                        </h2>{" "}
                        <textarea
                          onChange={(event) => setTitle(event.target.value)}
                          value={title}
                          placeholder="Enter Title..."
                          rows="2"
                          cols="70"
                          style={{ marginTop: "1%", fontSize: 18 }}
                        />
                      </div>
                      <div>
                        <h2
                          style={{
                            color: "blue",
                            fontSize: 18,
                            marginTop: "2%",
                          }}
                        >
                          Body :
                        </h2>{" "}
                        <textarea
                          onChange={(event) => setBody(event.target.value)}
                          value={body}
                          placeholder="Enter Body..."
                          rows="5"
                          cols="70"
                          style={{ marginTop: "1%", fontSize: 18 }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "25px",
                        gap: 15,
                      }}
                    >
                      <Button
                        style={{ marginRight: "10px", fontWeight: "bold" }}
                        onClick={closeNotification}
                      >
                        Cancel
                      </Button>
                      {/* <Button  color="primary" onClick={togglePopup} style={{fontWeight:"bold"}}> <FaMailBulk style={{  color: "#fff" }} size={24}  /> Send as Email </Button>
                <Button  color="primary" onClick={togglePopup}  style={{fontWeight:"bold"}}> <FiMessageSquare style={{ color: "#fff" }} size={24} />Send as Message</Button> */}
                      <Button
                        color="primary"
                        onClick={() => handleSubmit("fcm", row._id)}
                        style={{ fontWeight: "bold" }}
                      >
                        {" "}
                        <FaBell style={{ color: "#fff" }} size={24} />
                        Send as Notification
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            );
          },
          sortable: true,
          center: true,
        }
      : "",

    icons: {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <span>
              {hideDirector && (
                <i
                  style={{
                    cursor: "pointer",
                    width: 35,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(40, 167, 69)",
                  }}
                  onClick={() => onViewNavigate(row)}
                  className="fa fa-eye"
                ></i>
              )}
            </span>

            <span>
              <i
                className="fa fa-pencil"
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
                onClick={() => onUpdateUser(row)}
              ></i>
            </span>
            <span>
              <i
                className="fa fa-trash"
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
                onClick={() => onRemoveUser(row.mobile)}
              ></i>
            </span>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  };

  var conf = localStorage.getItem("userlistconf");

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: "id", title: "Id", selected: true },
      { value: "name", title: "Name", selected: true },
      { value: "email", title: "Email", selected: true },
      { value: "mobile", title: "Mobile", selected: false },
      { value: "type", title: "Type", selected: false },
      {
        value: "numberofbookings",
        title: "Number of Bookings",
        selected: false,
      },
      { value: "orgname", title: "Org Name", selected: true },

      { value: "date", title: "Date", selected: false },
      {
        value: "sendnotifications",
        title: "Send notifications",
        selected: true,
      },

      { value: "icons", title: "icons", selected: true },
    ];
  }

  const [filter, setFilter] = useState(conf);

  const content =
    filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {
    if (content.includes(d)) {
      tableColumns.push(tableData[d]);
    }
  });

  //  useEffect(()=>{
  // if( status === "Admin"){
  //    return tableColumns?.filter(i => i.name !== "View")
  //     }else {
  //      [ tableColumns]
  //     }
  //   },[])

  const onViewNavigate = (row) => {
    history(`${process.env.PUBLIC_URL}/app/listingdata/${row._id}`, {
      state: row,
    });
  };

  const handler = async (id) => {
    // let qryString = "";
    // qryString = new URLSearchParams({ userId: id }).toString();

    const response = await adminServices.exportUsers({});

    const href = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `users_${moment().format("YYYY-MM-DD-HH-mm")}`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const onRemoveUser = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const res = await adminServices.deleteUser(id);
        if (res.status) {
          SweetAlert.fire("Deleted!", "Your User has been deleted.", "success");
          getUsers();
        } else {
          SweetAlert.fire("Please try again");
        }
      } else {
        SweetAlert.fire("Please try again");
      }
    });
  };
  const onUpdateUser = (row) => {
    history(`${process.env.PUBLIC_URL}/app/user/${row._id}`, { state: row });
  };

  const openPopHandler = () => {
    setVaryingContentone(!VaryingContentone);
    setFilter(filter);
  };
  const showhideHandler = (values, checked) => {
    const index = filter.findIndex((val) => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageItems(newPerPage);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Table"
        title={status === "User" ? "Users" : "Admin"}
        //   buttonOptions={() => <Button color="primary"
        //     onClick={() => handler()}
        //   >Export</Button>
        // }
        renderOptions={() => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <CiExport
                onClick={() => handler()}
                style={{
                  color: "#d63384",
                  marginRight: "6%",
                  fontWeight: "bold",
                }}
                size={28}
              />

              <div>
                {status !== "Admin" && (
                  <Button
                    color="primary"
                    onClick={() =>
                      history(`${process.env.PUBLIC_URL}/app/user/${layout}`)
                    }
                  >
                    {"Add"}
                  </Button>
                )}
              </div>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => openPopHandler()}
              >
                Filter
              </Button>
            </div>
          );
        }}
      />
      <div style={{ alignSelf: "flex-start" }}></div>
      {loader ? (
        <Loading />
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <Modal
                  isOpen={VaryingContentone}
                  style={{ width: "30%" }}
                  onClosed={() =>
                    localStorage.setItem("userlistconf", JSON.stringify(filter))
                  }
                  toggle={() => setVaryingContentone(!VaryingContentone)}
                >
                  <ModalHeader
                    toggle={() => setVaryingContentone(!VaryingContentone)}
                  >
                    {"Display"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      {filter.map((i) => {
                        return (
                          <div style={{ margin: "20px" }}>
                            <input
                              type="checkbox"
                              defaultChecked={i.selected}
                              value={i.selected}
                              name={i}
                              onChange={(e) =>
                                showhideHandler(i, e.target.checked)
                              }
                            />
                            <span style={{ marginLeft: "30px" }}>
                              {i.title}
                            </span>
                          </div>
                        );
                      })}
                    </Form>
                  </ModalBody>
                </Modal>

                <CardBody>
                  <DataTable
                    data={data}
                    columns={tableColumns}
                    center={true}
                    selectableRows
                    pagination
                    paginationServer
                    paginationPerPage={pageItems}
                    paginationTotalRows={totalItems}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
export default UserList;
