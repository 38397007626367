import requests from "./httpservices";

const commonServices = {

    getDropdown(type) {
        return requests.get(`common/dropdown/${type}`)
    },
    getRole(type) {
        return requests.get(`common/${type}`)
    },
    getFeedbacks(type){
        return requests.get(`common/feedback/${type}`)
    }





}

export default commonServices;