import React, { Fragment, useState } from "react";
// import "../styles/styles.css"
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  ModalHeader,
  Input,
  Toast,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";

import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from "../../data/layouts";
import Select from "react-select";
import { useLocation } from "react-router";
import Loading from "./Loading";
import moment from "moment/moment";

import { useSearchParams } from 'react-router-dom';


import { CiExport } from "react-icons/ci";
import { toast } from "react-toastify";

const options = [
  { value: "supplier", label: "Suppliers" },
  { value: "renter", label: "Renter" },
];

const UserList = (props) => {
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [VaryingContentone, setVaryingContentone] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();


  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const [notesId, setShowNotes] = useState();

  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [status, setStatus] = useState({
    value: "supplier",
    label: "Suppliers",
  });
  const [currentPage, setPage] = useState(searchParams?.get("page") || 1);
  const [pageItems, setPageItems] = useState(searchParams?.get("items") || 10);
  const [totalItems, setTotalItems] = useState(0);

  const [defaultPage, setDefaultPage] = useState(searchParams?.get("page") || 1);

  const [sendEmail, setSendEmail] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const closeEmail = () => {
    setSendEmail(!sendEmail);
  };

  const closeMessage = () => {
    setSendMessage(!sendMessage);
  };

  const closeNotification = () => {
    setSendNotification(!sendNotification);
  };

  const togglePopup = () => {
    setSendNotification(!sendNotification);
  };

  const Popup = ({ onClose }) => {
    const [selectedIcon, setSelectedIcon] = useState(null);
  };

  async function getUsers(p) {
    setLoader(true);
    var filter = {
      type: "User",
      primary_account: status.value,
      page: p - 1,
      items: pageItems,
    };

    if (searchValue) {
      filter.search = searchValue;
    }

    const params = new URLSearchParams(filter);
    console.log("Params", params);
    const res = await adminServices.getUsers(params);
    if (res.status) {
      setLoader(false);
      // setHideDirector("Admin")
      setData(res.data.users);
      setTotalItems(res.data.total);
    } else {
      setData([]);
    }
  }
  useEffect(() => {
    getUsers(currentPage);
  }, [status, currentPage, pageItems, searchValue]);

  const handleSubmit = async (fcm, id) => {
    const res = await adminServices.sendNotification(fcm, id, {
      notification: {
        title: title,
        body: body,
      },
      android: {
        priority: "high",
      },
      data: {
        title: title,
        body: body,
        // navigate_to: "Listings",
        // user_type: "supplier",
        // screen: "Approved",
      },
    });
    if (res.status) {
      const msg = res.data;
      if (msg?.message) {
        toast(msg.message);
      } else {
        toast(res.message);
      }
    } else {
      toast.error(res.message);
    }
  };

  const tableData = {
    id: {
      name: "ID",
      selector: (row) => row._id,
      sortable: true,
    },

    name: {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    email: {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      center: true,
    },
    mobile: {
      name: "Mobile",
      selector: (row) => row?.mobile,
      sortable: true,
      center: true,
    },
    type: {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
      center: true,
    },

    orgname: {
      name: "Org name",
      selector: (row) => row?.organization_name,
      sortable: true,
      center: true,
    },
    nooflisting: {
      name: "Listings",
      selector: (row) => row?.listings,
      sortable: true,
      center: true,
    },
    bookings: {
      name: "Bookings",
      selector: (row) => row?.bookings,
      sortable: true,
      center: true,
    },
    notes: {
      name :"Notes",
      selector : (row, idx) => {
        return  idx == notesId ?  <Input
        placeholder={"Notes"}
        value={row.note}
        onBlur={async (event) => {
          if(event.target.value){
            const res = await adminServices.addNotes(row._id, {note: event.target.value});
            setShowNotes()
          }
        }}
        onChange={(e) => {
          let dd = JSON.parse(JSON.stringify(data));
          dd[idx].note = e.target.value;
          setData(dd);
        }}
      /> : <p onClick={() => setShowNotes(idx)}>{row.note || "---"}</p>
      }
    },
    icon: {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <span>
              <i
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
                onClick={() => onViewNavigate(row)}
                className="fa fa-eye"
              ></i>
            </span>

            <span>
              <i
                className="fa fa-pencil"
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
                onClick={() => onUpdateUser(row)}
              ></i>
            </span>
            <span>
              <i
                className="fa fa-trash"
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
                onClick={() => onRemoveUser(row.mobile)}
              ></i>
            </span>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
    sendNotification: {
      name: "notify",
      selector: (row) => {
        return (
          <div>
            <i
              class="fa fa-share-square"
              onClick={togglePopup}
              style={{ fontSize: "20px", color: "#0d6efd" }}
              aria-hidden="true"
            ></i>
            {isOpen && <Popup onClose={togglePopup} />}
            {sendEmail && (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  // width: "600px",
                  backgroundColor: "white",
                  padding: "40px",
                  borderRadius: "8px",
                  height: "40%",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 999,
                }}
              >
                <h5 style={{ justifyContent: "center" }}>Send Email</h5>

                <div style={{ padding: "10px 0px", marginTop: "0px" }}>
                  To :{" "}
                </div>
                <div style={{ padding: "5px 0px", marginTop: "0px" }}>
                  {" "}
                  <TextField id="standard-basic" label="Email" />{" "}
                </div>
                <div style={{ padding: "10px 0px", marginTop: "20px" }}>
                  Description :{" "}
                </div>

                <textarea
                  rows="4"
                  cols="60"
                  style={{ marginTop: "0%" }}
                ></textarea>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button style={{ marginRight: "10px" }} onClick={closeEmail}>
                    Cancel
                  </Button>
                  <Button onClick={togglePopup}>Send</Button>
                </div>
              </div>
            )}

            {sendMessage && (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  // width: "600px",
                  backgroundColor: "white",
                  padding: "40px",
                  borderRadius: "8px",
                  height: "30%",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 999,
                }}
              >
                <h5 style={{ justifyContent: "center" }}>Send Message</h5>

                <textarea
                  rows="4"
                  cols="60"
                  style={{ marginTop: "10%" }}
                ></textarea>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button style={{ marginRight: "5px" }} onClick={closeMessage}>
                    Cancel
                  </Button>
                  <Button onClick={togglePopup}>Send</Button>
                </div>
              </div>
            )}

            {sendNotification && (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "white",
                  padding: "40px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 999,
                }}
              >
                <h5 style={{ justifyContent: "center" }}>Send Notifications</h5>
                <div>
                  <div>
                    <h2
                      style={{
                        color: "blue",
                        fontSize: 18,
                        marginTop: "2%",
                      }}
                    >
                      Title :
                    </h2>{" "}
                    <textarea
                      onChange={(event) => setTitle(event.target.value)}
                      value={title}
                      placeholder="Enter Title..."
                      rows="2"
                      cols="70"
                      style={{ marginTop: "1%", fontSize: 18 }}
                    />
                  </div>
                  <div>
                    <h2
                      style={{
                        color: "blue",
                        fontSize: 18,
                        marginTop: "2%",
                      }}
                    >
                      Body :
                    </h2>{" "}
                    <textarea
                      onChange={(event) => setBody(event.target.value)}
                      value={body}
                      placeholder="Enter Body..."
                      rows="5"
                      cols="70"
                      style={{ marginTop: "1%", fontSize: 18 }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                    gap: 15,
                  }}
                >
                  <Button
                    style={{ marginRight: "10px", fontWeight: "bold" }}
                    onClick={closeNotification}
                  >
                    Cancel
                  </Button>
                  {/* <Button  color="primary" onClick={togglePopup} style={{fontWeight:"bold"}}> <FaMailBulk style={{  color: "#fff" }} size={24}  /> Send as Email </Button>
            <Button  color="primary" onClick={togglePopup}  style={{fontWeight:"bold"}}> <FiMessageSquare style={{ color: "#fff" }} size={24} />Send as Message</Button> */}
                  <Button
                    color="primary"
                    onClick={() => handleSubmit("fcm", row._id)}
                    style={{ fontWeight: "bold" }}
                  >
                    Send as Notification
                  </Button>
                </div>
              </div>
            )}
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  };

  var conf = localStorage.getItem("supplierconf");

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: "id", title: "Id", selected: true },
      { value: "name", title: "Name", selected: true },
      { value: "email", title: "Email", selected: true },
      { value: "mobile", title: "Mobile", selected: true },
      { value: "type", title: "Type", selected: false },
      { value: "orgname", title: "Org name", selected: false },
      { value: "nooflisting", title: "Listings", selected: true },
      {
        value:"bookings", title:"Bookigs", selected:true
      },
      {
        value:"notes", title:"Notes", selected:true
      },
      { value: "icon", title: "icons", selected: true },
      { value: "sendNotification", title: "Notify", selected: false },
    ];
  }

  const [filter, setFilter] = useState(conf);

  const content =
    filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {
    if (content.includes(d)) {
      tableColumns.push(tableData[d]);
    }
  });
  const onViewNavigate = (row) => {
    history(`${process.env.PUBLIC_URL}/app/listingdata/${row._id}`, {
      state: row,
    });
  };

  const handler = async (id) => {
    let searchParams = new URLSearchParams({primary_account:status.value});
    const response = await adminServices.exportUsers(searchParams);
    const href = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `users_${moment().format("YYYY-MM-DD-HH-mm")}`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const onRemoveUser = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const res = await adminServices.deleteUser(id);
        // console.log("res",res)
        if (res.status) {
          SweetAlert.fire("Deleted!", "Your User has been deleted.", "success");
          getUsers(currentPage);
        } else {
          SweetAlert.fire("Please try again!");
        }
      } else {
        SweetAlert.fire("Please try again!");
      }
    });
  };
  const onUpdateUser = (row) => {
    // history(`${process.env.PUBLIC_URL}/app/user/${row._id}`, { state: row });
    history(`${process.env.PUBLIC_URL}/app/user/${row._id}`, { state: row });
  };

  const openPopHandler = () => {
    setVaryingContentone(!VaryingContentone);
    setFilter(filter);
  };
  const showhideHandler = (values, checked) => {
    const index = filter.findIndex((val) => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setSearchParams({page: page, items : pageItems})
    setDefaultPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(page);
    setPageItems(newPerPage);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Table"
        title={status.label}
        buttonOptions={() => (
          <div style={{ marginLeft: "8%", width: "100%" }}>
            <Select
              value={status}
              defaultValue={status}
              onChange={(event) => {
                setSearchText("");
                setSearchValue("");
                setPage(1);
                setStatus(event);
              }}
              options={options}
            />
          </div>
        )}
        renderOptions={() => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <CiExport
                onClick={() => handler()}
                style={{
                  color: "#d63384",
                  marginRight: "6%",
                  fontWeight: "bold",
                }}
                size={28}
              />

              <div>
                <Button
                  color="primary"
                  onClick={() =>
                    history(`${process.env.PUBLIC_URL}/app/user/${layout}`)
                  }
                >
                  {"Add"}
                </Button>
              </div>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => openPopHandler()}
              >
                Filter
              </Button>

              {/* <div>
                {status !== "Admin" && (
                  <Button
                    color="primary"
                    onClick={togglePopup}
                  >
                    <i class="fa fa-share-square"
                      // onClick={togglePopup} 
                      style={{ fontSize: "20px", color: "white" }} aria-hidden="true"></i>
                  </Button>
                )}
              </div> */}
            </div>
          );
        }}
      />
      <Row>
        <Col sm="8" />
        <Col sm="4">
          <div
            style={{ display: "flex", flexDirection: "row", margin: "20px" }}
          >
            <Input
              placeholder={"search"}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                setPage(1);
                setSearchValue(searchText);
              }}
              style={{ marginLeft: "10px" }}
              className="btn-pill"
            >
              {"search"}
            </Button>
          </div>
        </Col>
      </Row>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  center={true}
                  selectableRows
                  progressPending={loader}
                  pagination
                  paginationServer
                  currentPage={currentPage}
                  paginationPerPage={pageItems}
                  paginationTotalRows={totalItems}
                  paginationDefaultPage={defaultPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={VaryingContentone}
          style={{ width: "30%" }}
          onClosed={() =>
            localStorage.setItem("supplierconf", JSON.stringify(filter))
          }
          toggle={() => setVaryingContentone(!VaryingContentone)}
        >
          <ModalHeader toggle={() => setVaryingContentone(!VaryingContentone)}>
            {"Display"}
          </ModalHeader>
          <ModalBody>
            <Form>
              {filter.map((i) => {
                return (
                  <div style={{ margin: "20px" }}>
                    <input
                      type="checkbox"
                      defaultChecked={i.selected}
                      value={i.selected}
                      name={i}
                      onChange={(e) => showhideHandler(i, e.target.checked)}
                    />
                    <span style={{ marginLeft: "30px" }}>{i.title}</span>
                  </div>
                );
              })}
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
};
export default UserList;
