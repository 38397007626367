export const DEV_URL = "http://develop.panrentals.com/";

export const TEST_URL = "https://pan-develop-app.azurewebsites.net/";
export const UAT_URL = "https://uat.panrentals.com/";
export const PROD_URL = "https://app.panrentals.com/";

export const LOCAL_URL = "http://localhost:5004/";

export const THEME_URL = "http://183.83.32.34:5003/";

export const URL = "http://13.232.68.223:5003/";
export const URL1 = "http://3.111.168.248:5003/";

//  export const API_BASE_URL = "https://apis.panrentals.com/"

//  export const base_URL = "http://apis.techdeets.net:5003/
//  export const API_BASE_URL = "https://uat.panrentals.com/";
//  export const API_BASE_URL = "https://pan-develop-app.azurewebsites.net/";
export const API_BASE_URL = PROD_URL;
// export const API_BASE_URL = UAT_URL;
