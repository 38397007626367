import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Form, } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import moment from "moment/moment";
import { classes } from '../../data/layouts'
import Breadcrumb from "../../layout/breadcrumb";

import Select from 'react-select'
import Loading from "./Loading";

const options = [
  { value: 'all', label: 'All' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Submitted', label: 'Reserved' }
]

const SubmittedBookings = () => {

  const [data, setData] = useState("")

  const [status, setStatus] = useState("Pending");

  const [selectedRows, setSelectedRows] = useState([]);

  const [showQuotation, setShowQuotation] = useState(false);

  const [quotation, setQuotation] = useState({});

  const [loader, setLoader] = useState(false)
  const [VaryingContentone, setVaryingContentone] = useState(false);

  const [currentPage, setPage] = useState(1);
  const [pageItems, setPageItems] = useState(10);
  const [totalItems, setTotalItems] = useState(0);



  const history = useNavigate();
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  var object = {
    status: "Pending",
  };
  const objString = new URLSearchParams(object).toString();
  useEffect(() => {
    setLoader(true)
    async function getReservations() {
      let qryString = "";
      let obj = {};
        if (status !== "all") {
          obj.status = status;
      }
      obj = {...obj, page: currentPage-1, items: pageItems}
      qryString = new URLSearchParams(obj)
      const res = await adminServices.getReservations(qryString);
      if (res.status) {
        setLoader(false)
        setData(res.data.reservations);
        setTotalItems(res.data.total);
      } else {
        setData([]);
      }
    }
    getReservations();
  }, [status, currentPage, pageItems])
  const ModalToggle = () => setShowQuotation(!showQuotation);

  const saveQuotation = async () => {


    // let ids = data.map((d) => d._id);

    // const enqids = { ...quotation, enquries: ids, user_id: booking.user_id };
    // const res = await adminServices.saveQuotation(booking._id, enqids);
    // if (res.status) {
    //     ModalToggle();
    // } else {
    //     console.log("XJKK", res);
    // }
  }


  const onChangeText = (event, key) => {
    setQuotation(data => {
      return { ...data, [key]: event.target.value };
    })
  }


  const tableData = {
    name: {
      name: 'Name',
      selector: (row) => row.uid,
      sortable: true,
    },
    username: {
      name: 'Name',
      selector: (row) => row.user_id?.name,
      sortable: true,
    },
    email: {
      name: 'Email',
      selector: (row) => row.user_id?.email,
      sortable: true,
    },
    mobile: {
      name: 'Mobile Number',
      selector: (row) => row.user_id?.mobile,
      sortable: true,
    },
    createdat: {
      name: 'Created Time',
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    status: {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
    },
    view: {
      name: 'View',
      selector: (row) => {
        return (
          <div>
            <Button color="primary" onClick={() => onViewNavigate(row)}>
              View
            </Button>
          </div>
        );
      },
      sortable: true,
      center: true,
    },


  };


  var conf = localStorage.getItem("submitbookingsconf");
  console.log("CONF", conf);

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: 'name', title: "Name", selected: true },
      { value: 'username', title: "User name", selected: true },
      { value: 'email', title: "Email", selected: false },
      { value: 'mobile', title: "Mobile", selected: false },
      { value: 'createdat', title: "Created At", selected: false },
      { value: 'status', title: "Status", selected: false },
      { value: 'view', title: "View", selected: true },
    ]
  }

  const [filter, setFilter] = useState(conf);

  const content = filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {

    if (content.includes(d)) {

      tableColumns.push(tableData[d]);
    }
  })

  const onViewNavigate = (row) => {

    // history(`${process.env.PUBLIC_URL}/app/bookingDetails/${row._id} `, { state: row });
    history(`${process.env.PUBLIC_URL}/app/reservedList/${row._id} `, { state: row });

  }

  const onViewEnquiries = (row) => {
    history(`${process.env.PUBLIC_URL}/app/bookingEquiries/${row._id}`, { state: row });
  }

  const handleRowSelected = React.useCallback(state => {
    // setSelectedRows(state.selectedRows);
    let rows = state.selectedRows;
    let selectedId = []
    // {
    //     rows.map((d) => {
    //         return selectedId.push(d._id)
    //     })
    // }
    setSelectedRows(rows);
    // console.log("selectedId", selectedId)
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(page);
    setPageItems(newPerPage);
  };

  const contextActions = React.useMemo(() => {
    return (
      <Button key="delete"
        onClick={() => { }}
        style={{ backgroundColor: 'green' }} icon>
        Generate Quotation
      </Button>
    );
  }, [selectedRows]);

  console.log("X", selectedRows);

  const openPopHandler = () => {
    setVaryingContentone(!VaryingContentone)
    setFilter(filter)
  }
  const showhideHandler = (values, checked) => {

    const index = filter.findIndex(val => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Table" title="Reservations" renderOptions={() =>
        <Select value={status} onChange={event => {
          setStatus(event.value);
        }} styles={{ width: '20%', margin: '2%' }} options={options} />}


        buttonOptions={() => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button style={{ marginLeft: "10px" }} onClick={() => openPopHandler()}>Filter</Button>

            </div>
          );
        }}
      />


      <Container fluid={true}>
        {
          selectedRows.length > 0 && <div style={{ display: 'flex', justifyContent: "flex-end", marginBottom: '10px' }}><Button onClick={() => setShowQuotation(!showQuotation)}>Generate Quotation</Button> </div>
        }
        <Row>
          <Col sm="12">
            <Card>

              <Modal isOpen={VaryingContentone} style={{ width: "30%" }}
                onClosed={() => localStorage.setItem("submitbookingsconf", JSON.stringify(filter))}
                toggle={() => setVaryingContentone(!VaryingContentone)}
              >
                <ModalHeader
                  toggle={() => setVaryingContentone(!VaryingContentone)}
                >
                  {"Display"}
                </ModalHeader>
                <ModalBody>
                  <Form>

                    {filter.map(i => {
                      return (<div style={{ margin: "20px" }}>
                        <input type="checkbox" defaultChecked={i.selected} value={i.selected} name={i} onChange={(e) => showhideHandler(i, e.target.checked)} />
                        <span style={{ marginLeft: "30px" }}>{i.title}</span>
                      </div>)
                    })}


                  </Form>
                </ModalBody>
              </Modal>

              <CardBody>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  // selectableRows
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  center={true}
                  pagination
                  paginationServer
                  progressPending={loader}
                  paginationPerPage={pageItems}
                  paginationTotalRows={totalItems}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}

                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal size="lg" isOpen={showQuotation} toggle={ModalToggle}>
          <ModalHeader toggle={ModalToggle} >
            {"New Quotation"}
          </ModalHeader>
          <ModalBody>

            <Container fluid={true} className="bd-example-row">
              {
                selectedRows && selectedRows?.map((d) => {
                  return <div className="mt-3">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                      <div>
                        <p>UID</p>
                        <p>{d.uid}</p>
                      </div>
                      <div>
                        <p>Qty</p>
                        <p>{d.quantity}</p>
                      </div>
                      <div>
                        <p>Duration</p>
                        <p>{d.uid}</p>
                      </div>
                    </div>
                    <Row>
                      <Col md="4">
                        <Input defaultValue={d.quantity} onChange={(event) => onChangeText(event, "quantity")} value={quotation.quantity} placeholder="Quantity" />
                      </Col>

                      <Col md="4">
                        <Input onChange={(event) => onChangeText(event, "amount")} value={quotation.amount} placeholder="Amount" />
                      </Col>
                      <Col md="4">
                        <p>Price</p>
                        {/* <Input onChange={(event) => onChangeText(event, "amount")} value={quotation.amount} placeholder="Amount" /> */}
                      </Col>
                    </Row>
                    <hr />

                  </div>
                })
              }
              <Row className="mt-3">
                <Col md="12">
                  Terms
                </Col>
                <Col md="12" className="ms-auto">
                  <Input onChange={(event) => onChangeText(event, "terms")} value={quotation.terms} type="textarea" placeholder="Terms" />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md="12">
                  Remarks
                </Col>
                <Col md="12" className="ms-auto">
                  <Input onChange={(event) => onChangeText(event, "remarks")} value={quotation.remarks} type="textarea" placeholder="Remarks" />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={ModalToggle}>{"Close"}</Button>
            <Button color="primary" onClick={() => saveQuotation()}>{"SaveChanges"}</Button>
          </ModalFooter>
        </Modal>

      </Container>
    </Fragment>
  )

}
export default SubmittedBookings;